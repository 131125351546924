import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';

function Footer(){

return(


<Fragment>



<footer>
<div className="container-xxl">
<div className="row">

{/* <div className="col-lg-2 col-sm-4">
<div className="footer-nav mb-4 mb-lg-0">
<p className="nav-title">What We Do</p>
<a href="what-we-do.html">Approach</a>
<a href="what-we-do/corporate-credit.html">Corporate Credit</a>
<a href="what-we-do/asset-backed-finance.html">Asset-Backed Finance</a>

</div>
</div> */}

<div className="col-lg-2 col-sm-4">
<div className="footer-nav mb-4 mb-lg-0">
<p className="nav-title">About Us</p>



<Link to="/About">Overview</Link>

{/* <a href="who-we-are/impact.html">Impact</a> */}
</div>
</div>
<div className="col-lg-2 col-sm-4">
<div className="footer-nav mb-4 mb-lg-0">
<p className="nav-title">Portofolio</p>

<Link to="/Portofolio">See Our Portofolio</Link>

{/* <a href="investors.html">See Our Portofolio</a> */}
{/* <a href="investors/financial-advisors.html">Financial Advisors</a>
<a href="investors/insurance.html">Insurance</a> */}
</div>
</div>
<div className="col-lg-2 col-sm-4">

</div>

{/* <div className="col-lg-1 offset-lg-2 col-sm-2">

<div className="footer-nav ps-0 border-0">
<a href="careers.html">Careers</a>
<a href="contact.html">Contact</a>
<a href="https://fort.seiinvestorportal.com/CoreAuth/Login">LP Login</a>
</div>
</div> */}

{/* social */}
<div className="col-sm-1">
<div className="social-follow">
{/* <a href="#" target="_blank"><span className="icon-linkedin-square"></span></a> */}
</div>
</div>

</div>

<div className="row cw-row">
<div className="col-md-8 order-last order-md-first">
    <div className="copyright">
<p className="mb-0">Copyright &copy; 2024 Hope Group</p>
    </div>
</div>


<div className="col-md-4 text-md-end mb-4">

<Link to="/" className="brand-footer">
{/* <a href="index.html" className="brand-footer">   
    
    <img src="assets/images/layout/logo1.png" />
</a> */}

<img src="assets/images/layout/logo1.png" />
</Link>   
</div>
</div>
</div>
</footer>



</Fragment>
)
}

export default Footer;