import React, { useEffect, Fragment } from 'react';
import Nav from "./Nav";
import Footer from "./Footer";
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom';

function Gallery(){


  useEffect(() => {
    // Dynamically add the script
    const script = document.createElement('script');
    script.src = '/imgpopup.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

    return(
    <Fragment>
      <Nav />



<main className="pt-100">
  
<section className="page-heading">
<div className="container-xxl">
<div className="row">
<div className="col-lg-6 mx-auto">
<h1>Welcome to Our Gallery</h1>
{/* <h2>-</h2> */}
</div>
</div>
</div>
</section>



<section className="bg-primary bg-light">
    <div className="container-xxl">
    <p className="section-title mb-8 companystructure">Mataer Parking</p>
    <div className="row">
    <div className="col-md-11 mx-auto">
    <div className="video-container"> 
    <Fade bottom> 
    {/* <img src="assets/images/structure.png" className="" width= "100%" alt="">
        </img> */}
 
    
    





     <div className="containergal">
  <div className="container__img-holder">
    <img src="assets/images/gallery/mataer/IMG_FED7668A6DFB-1.jpeg" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/mataer/Screenshot 2024-02-06 at 14.10.41.png" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/mataer/Screenshot 2024-02-06 at 14.10.54.png" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/mataer/Screenshot 2024-02-06 at 14.11.02.png" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/mataer/Screenshot 2024-02-06 at 14.11.13.png" alt="Image"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/mataer/Screenshot 2024-02-06 at 14.11.22.png" alt="Image"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/mataer/Screenshot 2024-02-06 at 14.11.36.png" alt="Image"/>
  </div>






  
</div>
</Fade>

<div className="img-popup">
  <img src="" alt="Popup Image"/>
  <div className="close-btn">
    <div className="bar"></div>
    <div className="bar"></div>
  </div>
</div>

    </div>
    </div>
    </div>
    </div>
    </section>

    <section className="bg-primary">
    <div className="container-xxl">
    <p className="section-title mb-8 companystructure">One Minute Print</p>
    <div className="row">
    <div className="col-md-11 mx-auto">
    <div className="video-container"> 
    <Fade bottom> 
    
     <div className="containergal">
  <div className="container__img-holder">
    <img src="assets/images/gallery/oneminute/8fe308e9-caa9-41d2-9646-95cbb0a47a00.JPG" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/oneminute/IMG_3750.jpg" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/oneminute/IMG_3752.jpg" alt="Image/"/>
  </div>


  
</div>
</Fade>

<div className="img-popup">
  <img src="" alt="Popup Image"/>
  <div className="close-btn">
    <div className="bar2"></div>
    <div className="bar2"></div>
  </div>
</div>

    </div>
    </div>
    </div>
    </div>
    </section>


    <section className="bg-primary bg-light">
    <div className="container-xxl">
    <p className="section-title mb-8 companystructure">Resto Apung Muara Angke</p>
    <div className="row">
    <div className="col-md-11 mx-auto">
    <div className="video-container"> 
    <Fade bottom> 
  

     <div className="containergal">
  <div className="container__img-holder">
    <img src="assets/images/gallery/restoapung/Screenshot 2024-02-06 at 14.11.54.png" alt="Image/"/>
  </div>

  <div className="container__img-holder">
    <img src="assets/images/gallery/restoapung/Screenshot 2024-02-06 at 14.12.01.png" alt="Image/"/>
  </div>

  <div className="container__img-holder">
    <img src="assets/images/gallery/restoapung/Screenshot 2024-02-06 at 14.12.06.png" alt="Image/"/>
  </div>


  <div className="container__img-holder">
    <img src="assets/images/gallery/restoapung/Screenshot 2024-02-06 at 14.12.13.png" alt="Image/"/>
  </div>

  <div className="container__img-holder">
    <img src="assets/images/gallery/restoapung/Screenshot 2024-02-06 at 14.12.20.png" alt="Image/"/>
  </div>


  
</div>
</Fade>

<div className="img-popup">
  <img src="" alt="Popup Image"/>
  <div className="close-btn">
    <div className="bar"></div>
    <div className="bar"></div>
  </div>
</div>

    </div>
    </div>
    </div>
    </div>
    </section>


    <section className="bg-primary">
    <div className="container-xxl">
    <p className="section-title mb-8 companystructure">Sembada Karya Mandiri</p>
    <div className="row">
    <div className="col-md-11 mx-auto">
    <div className="video-container"> 
    <Fade bottom> 
   
        <div className="containergal">
  <div className="container__img-holder">
    <img src="assets/images/gallery/sembada/911a167e-f4b4-4aff-b799-c09dee21349f.JPG" alt="Image/"/>
  </div>

  <div className="container__img-holder">
    <img src="assets/images/gallery/sembada/a6b1e8db-66b2-4260-849e-a6b03a21c66d.JPG" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/sembada/IMG_3859.jpg" alt="Image/"/>
  </div>

  <div className="container__img-holder">
    <img src="assets/images/gallery/sembada/IMG_3860.jpg" alt="Image/"/>
  </div>

  <div className="container__img-holder">
    <img src="assets/images/gallery/sembada/IMG_3861.jpg" alt="Image/"/>
  </div>



  
</div>
</Fade>

<div className="img-popup">
  <img src="" alt="Popup Image"/>
  <div className="close-btn">
    <div className="bar"></div>
    <div className="bar"></div>
  </div>
</div>

    </div>
    </div>
    </div>
    </div>
    </section>


    <section className="bg-primary bg-light">
    <div className="container-xxl">
    <p className="section-title mb-8 companystructure">Spotless</p>
    <div className="row">
    <div className="col-md-11 mx-auto">
    <div className="video-container"> 
    <Fade bottom> 

     <div className="containergal">
  <div className="container__img-holder">
    <img src="assets/images/gallery/spotless/Screenshot 2024-02-06 at 14.19.58.png" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/spotless/Screenshot 2024-02-06 at 14.20.09.png" alt="Image/"/>
  </div>
   <div className="container__img-holder">
    <img src="assets/images/gallery/spotless/Screenshot 2024-02-06 at 14.20.36.png" alt="Image/"/>
  </div> 
  <div className="container__img-holder">
    <img src="assets/images/gallery/spotless/Screenshot 2024-02-06 at 14.21.14.png" alt="Image/"/>
  </div>






  
</div>
</Fade>

<div className="img-popup">
  <img src="" alt="Popup Image"/>
  <div className="close-btn">
    <div className="bar"></div>
    <div className="bar"></div>
  </div>
</div>

    </div>
    </div>
    </div>
    </div>
    </section>

    <section className="bg-primary">
    <div className="container-xxl">
    <p className="section-title mb-8 companystructure">Dejia Catering</p>
    <div className="row">
    <div className="col-md-11 mx-auto">
    <div className="video-container"> 
    <Fade bottom> 
   


     <div className="containergal">
  <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/_DSC8250.JPG" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/_DSC8252.JPG" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/20220625_093159.png" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/20220625_094905.png" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/IMG_8470.JPG" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/IMG_8515.JPG" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/IMG_8707.JPG" alt="Image/"/>
  </div>
  <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/IMG_8752.JPG" alt="Image/"/>
  </div>
   <div className="container__img-holder">
    <img src="assets/images/gallery/dejia/IMG_8759.JPG" alt="Image/"/>
  </div>
</div>
</Fade>

<div className="img-popup">
  <img src="" alt="Popup Image"/>
  <div className="close-btn">
    <div className="bar"></div>
    <div className="bar"></div>
  </div>
</div>

    </div>
    </div>
    </div>
    </div>
    </section>
















</main>

<Footer />
  </Fragment>

    )
}

export default Gallery;