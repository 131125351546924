import React, { Fragment } from 'react';
import Nav from "./Nav";
import Footer from "./Footer";
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom';

function About(){

 
    return(
    <Fragment>
      <Nav />
      

<main className="pt-100">
         
<section className="page-heading">
<div className="container-xxl">
<div className="row">
<div className="col-lg-6 mx-auto">
<h1>Who We Are</h1>
<h2>Meet Hope Group</h2>
</div>
</div>
</div>
</section>

<div className="container-xxl pt-100 pb-100">

<div className="row">
  <Fade left> 
<div className="col-lg-4 col-md-5 mb-4 mb-md-0">
<img src="assets/images/people/1.png" className="img-fluid" alt=""/>
</div></Fade>
<div className="col-lg-5 col-md-7">
  <Fade>
<h1>Prabadika Reyhan Fernaldy,<br></br> S.M., M.M.</h1>
<p className="fw-medium">President Director</p>
</Fade>
<Fade bottom>
<ul className="bulletin">
  <li>President Commisioner at PT Bersih Seperti Baru</li>
  <li>President Commisioner at PT Asmaraloka Boga
Cateria</li>
  <li>Director of Finance at PT Mata Aer Makmurindo</li>
  <li>Director of Finance at PT Sembada Karya Mandiri </li>
  <li>Master of Management (CUM LAUDE GRADUATE),
Universitas Indonesia</li>
  <li>Bachelor of Management, Business Management,
University of Prasetiya Mulya </li>

</ul>
</Fade>
</div>

<div className="col-lg-3 col-md-12">
  
<div className="">
  
<div className="related">

</div>
</div>
</div>
</div>
</div>
</main>



<main className="pt-50 pb-90 people2">
<div className="container-xxl pb-8">

<div className="row">

<Fade left>
<div className="col-lg-4 col-md-5 mb-4 mb-md-0">
<img src="assets/images/people/2.png" className="img-fluid" alt=""/>
</div>
</Fade>

{/* <div className="col-lg-3 col-md-12">  
<div className="">  
<div className="related">
</div>
</div>
</div> */}



<div className="col-lg-5 col-md-7">
  <Fade>
<h1>Prabadika Hadyan Romadhona, <br></br>S.E., M.B.A.</h1>
<p className="fw-medium">President Commisioner</p>
</Fade>
<Fade bottom>
<ul className="bulletin">
  <li>Co-Founder and President Director of PT Bersih Seperti Baru</li>
  <li>Co-Founder, Prosper Ventura</li>
  <li>President Commissioner, PT. Mata
Aer Makmurindo</li>
<li>Commissioner of PT. Sembada Karya Mandiri</li>
  <li>Master of Business Administration,
Institute Teknologi Bandung</li>
  <li>Bachelor of Economics, Business
Management, University of Prasetya
Mulya</li>



</ul>
</Fade>
</div>


</div>


</div>
</main>


      <main>
      <section className="bg-primary">
    <div className="container-xxl">
    {/* <p className="section-title mb-8 companystructure">Company Structure</p> */}
    <div className="row">

    <div className="col-md-11 mx-auto">
    <div className="video-container"> 

    <Fade> 
    <img src="assets/images/about/hopegroup1.png" className="" width= "100%" alt="">
        </img>
    </Fade>
       
    </div>
    </div>
    <Fade>
    <div className="col-lg-10 mx-auto">

    <Fade top> 
<p className=" text-center hopeinvestmentis"><span className="coklat">HOPE INVESTMENT </span>
 is a financial management company that pools capital from
individual and institutional investors to invest in various projects or financial
instruments.<span className="coklat"> HOPE INVESTMENT </span>manage investment portfolios on behalf of their
clients with the goal of <span>generating returns</span> through capital appreciation, income
generation, or both.</p>
</Fade>
<p className="coklat"></p>

</div>
</Fade>
</div>   
    </div>
   </section>





<section className="bg-light">  
<div className="container-xxl">
<div className="row gx-0">


<div className="col-lg-3">
<p className="section-title mb-4 mb-lg-8">Hope Group</p>

<Fade bottom> 
<p className="lg-section-title">Ownership and Control</p>
<p className="text-lg border-top pt-3">HOPE GROUP own the assets and control the operations
of the subsidiaries through ownership of their shares.</p>
</Fade> 

</div>


<div className="offset-lg-1 col-lg-8">

<div className="row mb-4">
<Fade bottom> 
<div className="col-lg-6">
<p className="lg-section-title">Risk Management</p>
<p className="text-lg border-top pt-3">By owning multiple subsidiaries operating in different industries
or markets, a holding company can diversify risk.</p>
</div>
</Fade> 
<Fade bottom> 
<div className="col-lg-6">

<p className="lg-section-title">Financial Management</p>
<p className="text-lg border-top pt-3">HOPE GROUP can centralize financial functions like
treasury management, tax planning, and capital allocation for their subsidiaries.</p>
</div>
</Fade> 
</div>
<div className="row mb-4">

<Fade bottom> 
<div className="col-lg-6">

<p className="lg-section-title">Legal Protection</p>
<p className="text-lg border-top pt-3">HOPE GROUP can offer legal protection by separating the
liabilities of different subsidiaries from each other and from the holding company
itself.
</p>
</div>
</Fade> 
<Fade bottom> 
<div className="col-lg-6">

<p className="lg-section-title">Strategic Flexibility</p>
<p className="text-lg border-top pt-3">HOPE GROUP can implement various strategies such as
restructuring, mergers, acquisitions, or divestitures involving their subsidiaries.
</p>
</div>
</Fade> 
</div>
</div>
</div>
</div>
</section>



<section id="institutional-investors-solutions" className="bg-light2 pt-0 pb-100">
<div className="container-xxl">
<div className="row">
<div className="col-md-7 pt-100">
<Fade>
<p className="section-title mb-4 mb-lg-8">Our Vision and Mission</p>
</Fade>
<Fade top>

<p className="section-intro">To become the largest and
healthiest holding company in
Indonesia.</p><h2>Effectively managing and
imbuing value into subsidiaries to
attain sustainable advantages as
measurable outcomes.</h2>
</Fade>
{/* <p className="mt-5 mb-4"><a href="what-we-do.html" className="btn btn-bw">Learn More</a></p> */}
</div>
<div className="col-md-5 order-first">
<img src="assets/images/general/architecture-concrete-curves.png" className="img-fluid" alt=""/>
</div>
</div>
</div>
</section>




<section className="bg-primary">
    <div className="container-xxl">
    <p className="section-title mb-8 companystructure">Company Structure</p>
    <div className="row">
    <div className="col-md-11 mx-auto">
    <div className="video-container"> 
    <Fade bottom> 
    <div className="org-chart">
        <div className="parent">       <Fade bottom>
            <img src="assets/images/logos/logo1.png" alt="Hope Group Logo"/>   </Fade>
    
            <h2></h2>     
               <div className="line down"></div>
         
        </div>
     
        <div className="children">

        
      
            <div className="child">
            <div className="line up"></div> 
             <Link to="/Portofolio#oneminute" className="">
            <Fade bottom>
                <img src="assets/images/logos/oneminute.png" alt="One Minute Print Logo"/>

                </Fade>
                </Link> 

            </div>


           

            <div className="child">
            <div className="line up"></div>
            <Link to="/Portofolio#prabadikaaset" className="">
            <Fade bottom>
                <img src="assets/images/logos/prabadikaset.png" alt="Prabadika Aset Logo"/>
                </Fade>
                </Link> 

            </div>

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#restoapung" className="">
             <Fade bottom>
                <img src="assets/images/logos/restoapung.png" alt="Resto Apung Logo"/>
                </Fade>   
                </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#sembada" className="">
             <Fade bottom>
                <img src="assets/images/logos/sembada2.png" alt="Sembada Logo"/>
                </Fade> 
                </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>   
            <Link to="/Portofolio#mataerparking" className="">
            <Fade bottom>
            <img src="assets/images/logos/mataerparking.png" alt="Mataer Parking Logo"/>
            </Fade> 
             </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>   
            <Link to="/Portofolio#dejia" className="">
            <Fade bottom>
            <img src="assets/images/logos/dejia-catering.png" alt="Dejia Catering Logo"/>
            </Fade>   
              </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>   
            <Link to="/Portofolio#spotless" className="">
            <Fade bottom>
            <img src="assets/images/logos/spotless.png" alt="Spotless Logo"/>
            </Fade> 
                </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#mataerdigital" className="">
             <Fade bottom>
            <img src="assets/images/logos/mataerdigital.png" alt="Mataer Digital Logo"/>
            </Fade>
            </Link> 
            </div>
           

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#profex" className="">
             <Fade bottom>
            <img src="assets/images/logos/profex.png" alt="Profex Logo"/>
            </Fade>
            </Link>
            </div>

            <div className="child">
            <div className="line up"></div>   
            <Link to="/Portofolio#chapterone" className="">
            <Fade bottom>
            <img src="assets/images/logos/chapterone.png" alt="Chapter One Logo"/>
            </Fade></Link>
            </div>

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#blambangan" className="">
             <Fade bottom>
            <img src="assets/images/logos/blambangan.png" alt="Blambangan Logo"/>
            </Fade></Link>
            </div>
            <div className="child">
            <div className="line up"></div>   

            <Fade bottom>
            <img src="assets/images/logos/aktif.png" alt="Aktif Mencari Tahu Media Logo"/>
            </Fade>
                      </div>

         

        </div>
    </div>

     </Fade>
    
    
    </div>
    </div>
    </div>
    </div>
    </section>


    <Fade bottom>
<section id="insurance-icon-list">
<div class="container-xxl">
<div class="row justify-content-center gx-8">
<div class="col-lg-3 col-md-4 col-sm-6">
<div class="card h-100 py-4 py-lg-0">
<p class="mb-8"><span class="icon-bar-chart"></span></p>
<p class="card-title">ACCELERATE</p>
<p>3-6 Months</p>
</div>
</div>
<div class="col-lg-3 col-md-4 col-sm-6 border-left">
<div class="card h-100 py-4 py-lg-0">
<p class="mb-8"><span class="icon-brief-case"></span></p>
<p class="card-title">SAFE</p>
<p>Portofolio & Human Resources</p>
</div>
</div>
<div class="col-lg-3 col-md-4 col-sm-6 border-left">
<div class="card h-100 py-4 py-lg-0">
<p class="mb-8"><span class="icon-dollar-circle"></span></p>
<p class="card-title">PROFITABLE</p>
<p>ROI>8%</p>
</div>
</div>
</div>
</div>
</section>
</Fade>


    <section className="bg-secondary bg-lm">
    <div className="container-xxl">
    <div className="row">
    <div className="col-lg-10 mx-auto">
    <p className="quote-text accelerate2">“Accelerate Your Future Safely With HOPE”</p>
    <p className=" text-center startwithhope2">#StartWithHOPE</p>
    <p className="text-center mb-0 hopeinvestment">Hope Investment</p>
    </div>
    </div>
    </div>
    </section>
       
    </main>
    
      <Footer/>
        </Fragment>

    )
}

export default About;