import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import {NavLink} from 'react-router-dom';

function Nav() {

    return(

<Fragment>


<header className="fixed-top">
<Fade>
<nav className="navbar navbar-expand-lg">

<div className="container-xxl">
<Link to="/" className="navbar-brand"><img src="assets/images/layout/logo1.png" className="img-fluid" /></Link>

<button className="navbar-toggler x collapsed" type="button" data-bs-toggle="offcanvas" data-bs-target="#mblnav" aria-controls="mblnav" aria-expanded="false" aria-label="toggle mobile nav">
<span className="bar"></span>
<span className="bar"></span>
<span className="bar"></span>
</button>

<div id="main-nav" className="navbar-collapse collapse justify-content-end">

<ul className="nav first-level">

<li className="dropdown">

<NavLink className="nav-one"
  to="/About" 
  style={({ isActive }) => ({
    color: isActive ? 'white' : '',
    background: isActive ? '#C4AF8A' : '',
  })}
>
  About Us
</NavLink>
</li>
<li className="dropdown">





<NavLink className="nav-one"
  to="/Portofolio" 
  style={({ isActive }) => ({
    color: isActive ? 'white' : '',
    background: isActive ? '#C4AF8A' : '',
  })}
>
 Portofolio
</NavLink>


</li>

<li className="dropdown">
<NavLink className="nav-one"
  to="/Gallery" 
  style={({ isActive }) => ({
    color: isActive ? 'white' : '',
    background: isActive ? '#C4AF8A' : '',
  })}
>
Gallery
</NavLink>
</li>






<li className="sep"><a href="#" className="nav-one lc"></a></li>
<li>
    
<Link to="/" className="nav-one lc">WWW.HOPEGROUP.ID</Link>

    
    
    </li>

<li>
{/* <Link to="/Contact" className="nav-one lc"> Work With Us</Link>     */}


<NavLink className="nav-one lc"
  to="/Contact" 
  style={({ isActive }) => ({
    color: isActive ? 'white' : '',
    background: isActive ? '#C4AF8A' : '',
    paddingRight: isActive ? '20px' : '',
  })}
>
  Work With Us
</NavLink>









</li>

</ul>

</div>
</div>

</nav>

{/* <!-- mobile nav --> */}


<div id="mblnav" className="offcanvas offcanvas-end d-lg-none" tabindex="-1" aria-labelledby="mainNavLabel">
<Fade>
<ul className="mobile-nav">
<Link to="/About" className="mbl-one"> About Us</Link>   <br></br> 
<Link to="/Portofolio" className="mbl-one"> Portofolio</Link>  <br></br>  
<Link to="/Gallery" className="mbl-one"> Gallery</Link>  <br></br>  
<Link to="/Contact" className="mbl-one"> Work With Us</Link>     


</ul>
</Fade>
</div>

</Fade>
</header>









</Fragment>


    )
}
export default Nav;