import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Index from './pages/Index';
import About from './pages/About';
import Portofolio from './pages/Portofolio';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';


import ScrollToTop from "./scrollToTop";


function App() {
  return (
    <div className="App">

      
      <Router forceRefresh={true}>
      <ScrollToTop />
        <Routes>
          <Route exact path='/' element={<Index />} />
          <Route path='/about' element={<About />} />   
          <Route path='/portofolio' element={<Portofolio />} />    
          <Route path='/gallery' element={<Gallery />} />      
          <Route path='/contact' element={<Contact />} />      
        </Routes>
      </Router>
  
     </div>

  );
}

export default App;
