import React, { useEffect, Fragment } from 'react';
import Nav from "./Nav";
import Footer from "./Footer";
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Portofolio(){


  const { hash } = useLocation();

  useEffect(() => {
      if (hash) {
          const element = document.getElementById(hash.substring(1));
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [hash]);










    return(
    <Fragment>
      <Nav />



<main className="pt-100">
  
<section className="page-heading">
<div className="container-xxl">
<div className="row">
<div className="col-lg-6 mx-auto">
<h1>Our Esteemed Subsidiary</h1>
<h2>Proven Strategies for Sustainable Growth and Profitability</h2>
</div>
</div>
</div>
</section>











 <section className="bg-light-lightbrown" id="mataerparking">
<div className="container-xxl">
<div className="row">
  <Fade>
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/mataerparking.png" className="img-fluid" alt="mataer parking"/>
</div>
</Fade> 

<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-8">Mataer Parking</p>
{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<Fade>
<p>Our finest form of service is
Mataer Parking that has
been established since
2020 and has become a
good parking partner for
customers. Currently,
Mataer Parking has 8 points
of locations, and the
majority of it are in the South
Tanggerang area or BSD
City. We have 2 main head
offices located in BSD City
and Jakarta City with the
total of 67 employees
including BOD, BOC and
Managers.</p>
</Fade>

<Fade bottom>
<div className="row">
  
<div className="col-md-42 col-sm-6">
<div className="stat-item sm">
<span className="number">2020</span>
<span className="description">Established</span>
</div>
</div>

<div className="col-md-42 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">8</span>
<span className="description">Parking Location Point</span>
</div>
</div>

<div className="col-md-42 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">67</span>
<span className="description">Employees</span>
</div>
</div>

<div className="col-md-42 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">002</span>
<span className="description">Offices</span>
</div>
</div>



</div>


</Fade>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>

</div>
</div>
</section>





<section className="bg-light">
<div className="container-xxl">
<div className="row">
<Fade>
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0" id="dejia">
<img src="assets/images/portofolio/dejia-catering.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</div></Fade>
<div className="col-lg-7 col-md-8">
<Fade>
<p className="section-title mb-4 mb-lg-8">Dejia Catering</p>


{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<p>DEJIA CATERING is a brand that was founded in 2020. Dejia Catering started
from serving factory customers in the Cikarang and Karawang areas, now it
has spread its wings and expanded the market to the capital city of Jakarta
by taking on the wedding, event, corporate and all forms of events. other.
Currently, every day Dejia Catering serves 2,000 boxes of food.</p>
</Fade>
<Fade bottom>
  <div className="row">
<div className="col-md-4 col-sm-6">
<div className="stat-item sm">
<span className="number">2020</span>
<span className="description">Established</span>
</div>
</div>
<div className="col-md-4 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">2000+</span>
<span className="description">/ Day Serving Experience</span>
</div>
</div>
</div>

</Fade>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>

<section className="bg-light-lightbrown"  id="oneminute">
<div className="container-xxl">
<div className="row">
  <Fade>
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/oneminute.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</div>
</Fade>

<div className="col-lg-7 col-md-8">
<Fade>
<p className="section-title mb-4 mb-lg-8">One Minute Print</p>
</Fade>

{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}<Fade>
<p>ONE MINUTE PRINT is a business operating in the printing
sector which has the value of fast and complete service.All
fo rms of printing needs can be done at One Minute Print.
Currently we have many strategic partners, both companies,
individuals and political parties, to support their operations.</p></Fade>
<div className="row">
<div className="col-md-4 col-sm-6">
<div className="stat-item sm"><Fade bottom>
<span className="number">25+</span>
<span className="description">Supported Professional Printing Machines</span></Fade>
</div>
</div>

</div>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>

<Fade>
<section className="bg-light" id="spotless">
<div className="container-xxl">
<div className="row">
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<Fade bottom>
<img src="assets/images/portofolio/spotless.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</Fade>
</div>
<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-8">Spotless</p>
{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<p>SPOTLESS is a brand that has been built since 2015 with the
concept of a one stop solution for all forms of shoe matters.
Spotless has now been registered as a Shopee Mall partner and
has also become the top shoe cleaner product on the platform.
Currently, Spotless sells up to 15,000 products every month and
will continue to innovate in providing the best to customers.
</p>
<div className="row">
<Fade bottom>
<div className="col-md-4 col-sm-6">
<div className="stat-item sm">
<span className="number">2015</span>
<span className="description">Established</span>
</div>
</div>

<div className="col-md-4 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">15000+</span>
<span className="description">Product Selling / Month</span>
</div>
</div>
</Fade>

</div>


{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>
</Fade>

<Fade>
<section className="bg-light-lightbrown" id="restoapung">
<div className="container-xxl">
<div className="row">
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/restoapung.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</div>
<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-8">Resto Apung</p>
{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<p>RESTO APUNG MUARA ANGKE is an asset from the regional government which was
tendered to a private company to manage. We are the company that successfully
won the tender and have the right to manage 80 tentants and 2000m2 of assets
located on the sea. Until now, every month there are more than 10,000 visitors who
come to Resto Apung Muara Angke and this will continue to increase along with
improvements in service and cleanliness which will continue to be evaluated and
improved.

</p>
<div className="row">
<Fade bottom>
<div className="col-md-4 col-sm-6">
<div className="stat-item sm">
<span className="number">80</span>
<span className="description">Tenants managed in current area</span>
</div>
</div>

<div className="col-md-4 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">10.000+</span>
<span className="description">Visitors calculated per month</span>
</div>
</div>
</Fade>
</div>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>
</Fade>

<Fade>
<section className="bg-light" id="mataerdigital">
<div className="container-xxl">
<div className="row">
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/mataerdigital.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</div>
<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-8">Mataer Digital</p>
{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<p>PT Mataer Digital Nusantara is a national company that focuses on
educational technology development activities. Mataer Digital
provides various educational activity needs that are highly
dependent on the use of technology, both in the learning process
and operational activities. Currently we are partnered with reputable
universities including Trisakti University, Al-Azhar University, Bhakti
Kencana University and 800+ more. Our payment method has
already supported by BNI VA, Mandiri VA, BSI, etc. 
</p>
<div className="row">
<Fade bottom>
<div className="col-md-4 col-sm-6">
<div className="stat-item sm">
<span className="number">800+</span>
<span className="description">Partnered Universities</span>
</div>
</div>

<div className="col-md-4 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">3.5mil+</span>
<span className="description">Students Used</span>
</div>
</div>

<div className="col-md-4 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">50+</span>
<span className="description">Partners</span>
</div>
</div>

</Fade>
</div>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>
</Fade>


<Fade>
<section className="bg-light-lightbrown" id="sembada">
<div className="container-xxl">
<div className="row">
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/sembada.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</div>
<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-8">Sembada Karya Mandiri</p>
{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<p>PT. Sembada Karya Mandiri is a company operating in the field of
information technology and control systems, which offers electronic
system design and development services. Supported by quality human
resources and noble ideals to create products made by the nation’s
children that are internationally competitive. The company continues to
be committed to innovation and producing products of leading quality.
Since being founded in early 2012, our company has created innovative
products, including the design and construction of monitoring systems,
navigational systems and control systems. The system built includes
hardware, software and applications which are built in an integrated
manner.
</p>
<div className="row">


<div className="col-md-8 col-sm-6">
<div className="stat-item sm">
<span className="number2">Certified Indonesian Army Partner</span>
</div>
</div>






<Fade bottom>
<div className="col-md-4 col-sm-6  border-left">
<div className="stat-item sm">
<span className="number">2012</span>
<span className="description">Founded</span>
</div>
</div>

</Fade>



</div>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>
</Fade>

<Fade>
<section className="bg-light" id="profex">
<div className="container-xxl">
<div className="row">
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/profex.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</div>
<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-8">Profajar Excibit International</p>
{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<p>PT PROFAJAR EXCIBIT INTERNATIONAL is a company that operates in
the field of educational events, and has been established since 2003.
PROFEX has been formally supported by Lembaga Layanan
Pendidikan Tinggi (LLDIKTI), Dinas Pendidikan, and the Local
Government in organizing each event. Currently, PROFEX has several
flagship events, such as Pekan Pendidikan Tinggi Jakarta, which in
2024 has successfully held its 19th event at Istora Senayan for 3 days
and brought in more than 25,000 high school students. Exhibitors at
this event are well-known state and private universities in Indonesia,
such as UGM, UNBRAW, ITS, UNPAR, UPH, LSPR, BINUS, etc.
</p>
<div className="row">
<Fade bottom>
<div className="col-md-6 col-sm-6">
<div className="stat-item sm">
<span className="number2">LLDIKTI & DINAS PENDIDIKAN</span>
<span className="description">Officially Supported</span>
</div>
</div>

<div className="col-md-4 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">25.000+</span>
<span className="description">Students visit/event</span>
</div>
</div>

<div className="col-md-4 col-sm-6">
<div className="stat-item sm">
<span className="number">2003</span>
<span className="description">Established</span>
</div>
</div>

<div className="col-md-8 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number2">Partnered with well-known state and
private universities in Indonesia.
</span>
</div>
</div>

</Fade>
</div>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>
</Fade>


<Fade>
<section className="bg-light-lightbrown" id="chapterone">
<div className="container-xxl">
<div className="row">
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/chapterone.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</div>
<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-8">Chapter One</p>
{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<p>CHAPTER ONE is a product developed by PT BERSIH SEPERTA
BARU. This product focuses its market on vehicle maintenance.
Currently, CHAPTER ONE continues to innovate and improve its
products in order to provide the best quality for its customers.
CHAPTER ONE is available in various marketplaces and has sold
more than 15,000 products.
</p>
<div className="row">
<Fade bottom>
<div className="col-md-4 col-sm-6">
<div className="stat-item sm">
<span className="number">2020</span>
<span className="description">Established</span>
</div>
</div>
<div className="col-md-4 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">15.000+</span>
<span className="description">Product Selling</span>
</div>
</div>
</Fade>
</div>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>

</Fade>

<Fade>
<section className="bg-light" id="blambangan">
<div className="container-xxl">
<div className="row">
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/blambangan.png" className="img-fluid" alt="investment-approach-josh-drew.jpg"/>
</div>
<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-8">Blambangan Raya Perkasa</p>
{/* 
<h2>Our overriding objective is to protect and grow our investors’ capital</h2>
<div className="section-intro mb-4">
With discipline and rigor, we apply expertise honed through decades of investing through market, economic and credit cycles – and across asset classes, structures, geographies and industries.</div> */}
<p>PT. Blambangan Raya Perkasa is a Private National Campany Sector
operating in the industrial Maritime, located on the east coast of
Banyuwangi City, East Java. We believe this Maritime Industry is led and run
by Indonesian Expertise Human Resources who are competence in the field
of Shipping & Maritime Industrial Engineering. This company have innovating
and a high fighting spirit for SEA TOLL supports and offshore activities in
Indonesian territorial.

</p>
<div className="row">

{/* <div className="col-md-4 col-sm-6">
<div className="stat-item sm">
<span className="number">2020</span>
<span className="description">Established</span>
</div>
</div>

<div className="col-md-4 col-sm-6 border-left">
<div className="stat-item sm">
<span className="number">15.000+</span>
<span className="description">Product Selling</span>
</div>
</div> */}

</div>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>
</div>
</section>
</Fade>

<Fade>
<section className="bg-light-lightbrown" id="prabadikaaset">
<div className="container-xxl">
<div className="row">
<div className="col-lg-5 col-md-4 mb-5 mb-lg-0">
<img src="assets/images/portofolio/prabadika-aset.png" className="img-fluid"  alt="prabadika aset"/>
</div>
<div className="col-lg-7 col-md-8">
<p className="section-title mb-4 mb-lg-2">Prabadika Asset Management</p>



<div className="row">

<div className="container-asset">
<Fade bottom> 
    <div className="section-asset">
      <div className="image-asset">
        <img src="assets/images/portofolio/prabadika-taman.png" alt="Taman Jajan Prabadika"/>
      </div>
      <div className="text-asset prabadika">
        <h2>Taman Jajan Prabadika</h2>
        <p>Taman Jajan Prabadika is a 3500m2 culinary area located in the West Cikarang area consisting of more than 20 tenants and is enlivened with night market rides to attract visitors in the surrounding area.</p>
      </div>
    </div>
    </Fade>

    <Fade bottom> 
    <div className="section-asset">
    <div className="image-asset">
        <img src="assets/images/portofolio/prabadika-wisma.png" alt="Wisma Prabadika"/>
      </div>
      <div className="text-asset prabadika">
        <h2>Wisma Prabadika</h2>
        <p>Wisma Prabadika is a villa located in Cibalung, West Java which has an area of 800m2 consisting of facilities such as a swimming pool, large fish pond, football field, basketball court, mini golf course and various other interesting facilities. This villa is intended for large numbers of users, such as from agencies or offices because the villa's capacity reaches 250 people. Currently, Wisma Prabadika is actively operating and has reached the office market.</p>
      </div>
    
    </div>
    </Fade>

    <Fade bottom> 
    <div className="section-asset">
      <div className="image-asset">
        <img src="assets/images/portofolio/prabadika-kontrakan.png" alt="Prabadika Kontrakan"/>
      </div>
      <div className="text-asset prabadika">
        <h2>Prabadika Kontrakan</h2>
        <p>Prabadika Kontrak is a boarding house that has a target market of workers in the Cikarang area. There are more than 150 rooms currently occupied. We continue to develop businesses such as laundry, mini markets and others to be able to support additional income and provide the best facilities for tenants.</p>
      </div>
    </div>
    </Fade>
  </div>










</div>
{/* <p className="footnote mt-4 mb-0 text-center">As of March 31, 2024.</p> */}
</div>
</div>






</div>









</section>
</Fade>























</main>


















<Footer />



        </Fragment>

    )
}

export default Portofolio;