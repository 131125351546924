import React, { Fragment } from 'react';
import Nav from "./Nav";
import Footer from "./Footer";
import Fade from 'react-reveal/Fade';
function Contact(){
    return(
    <Fragment>
      <Nav />

<Fade>
<main className= "pt-200 bg-careers">
 <div className="container-xxl h-overflow">
<h1 className="mb-8">LETS WORK TOGETHER</h1>
<div className="row mb-4">
<div className="col-lg-5">
<p className="section-title">Key Contacts</p>
<p>For questions about Hope Group, its businesses and opportunities, please contact us by :</p>
</div>
</div>


<div className="border-bottom pb-90">
<div className="row">

<div className="col-lg-3 mb-4 mb-lg-0">
<p className="office-heading">Phone</p>
<span className="d-block">- Prabadika -</span>
<span className="d-block">+62 822 8434 2267</span>


</div>

<div className="col-lg-3 mb-4 mb-lg-0">

<p className="office-heading">Email</p>
<a href="mailto:hopeinvestment@hopeinvest
group.com">hopeinvestment@hopeinvest
group.com</a>



</div>


</div>
</div>
</div>



<section className="bg-primary bg-lm">
    <div className="container-xxl">
    <div className="row">
    <div className="col-lg-10 mx-auto">
    <p className="quote-text accelerate2"></p>
    <p className=" text-center startwithhope2"></p>
    <p className="text-center mb-0 hopeinvestment"></p>
    </div>
    </div>
    </div>
    </section>


</main>

</Fade>
<Footer />
        </Fragment>

    )
}

export default Contact;