import React, { useEffect, Fragment } from 'react';
import Nav from "./Nav";
import Footer from "./Footer";
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Index(){

    useEffect(() => {
        // Dynamically add the script
        const script = document.createElement('script');
        script.src = '/scrollbutton.js';
        script.async = true;
        document.body.appendChild(script);
    
        // Cleanup script when component unmounts
        return () => {
          document.body.removeChild(script);
        };
      }, []);







    return(
   <Fragment>     
   <Nav />
<main>
    
<div className="home-video-banner">

<Fade>
<div className="home-video-outer d-none d-md-block">
    
<video className="video" loop muted="1" autoplay="1" width="100%" poster="assets/home3.png">
<source src="" type="video/mp4"/>
</video>
</div>
</Fade>

<div className="home-content">
<Fade>
    <h1 className="ptlautan">PT LAUTAN REZEKI PRABADIKA</h1>
    </Fade>

    <Fade bottom>
  <h1 className="accelerate">HOPE GROUP</h1>
  <p className="accelerate3">Our successful investment strategies have consistently delivered <br></br>positive results for our clients.
</p>

{/* <h2 className="startwithhope">#StartWithHOPE</h2> */}
</Fade>
<Fade bottom>

<div className="learnmoresec">
<p>
    <Link to="/About" className="arrow-rgt learnmore">Learn More</Link>
    {/* <a href="who-we-are.html" className="btn btn-transparent">Learn More</a> */}
 </p>
 
 </div>
 </Fade>
</div>

<Fade bottom>

<p className="ctr">
<Link to="/#home-stats">
    <button className="btn-scroll">
        <span className="icon-arrow-down-circle"></span>
        </button>
        </Link>


        </p>
</Fade>
</div>
<section id="home-stats">
<div className="container-xxl">
<div className="row">
<div className="col-lg-4 col-sm-6">
<div className="stat-item nb">
<span className="number">$3.6 Million+</span>
<span className="description mb-4">Total Investment</span>

</div>
</div>
<div className="col-lg-4 col-sm-6 border-left">
<div className="stat-item nb">
<span className="number">15 Years+</span>
<span className="description mb-4">Experience in Business<br /><br /></span>

</div>
</div>
<div className="col-lg-4 col-sm-6 border-left">
<div className="stat-item nb">
<span className="number">325+</span>
<span className="description mb-4">Total Employees</span>

</div>
</div>
</div>

</div>

</section>


<section className="bg-primary">
<div className="container-xxl">
{/* <p className="section-title mb-8 companystructure">Company Structure</p> */}
<div className="row">
<div className="col-md-11 mx-auto">
<div className="video-container"> 
<Fade bottom> 
    <div className="org-chart">
        <div className="parent">       <Fade bottom>
            <img src="assets/images/logos/logo1.png" alt="Hope Group Logo"/>   </Fade>
    
            <h2></h2>     
               <div className="line down"></div>
         
        </div>
     
        <div className="children">

        
      
            <div className="child">
            <div className="line up"></div> 
             <Link to="/Portofolio#oneminute" className="">
            <Fade bottom>
                <img src="assets/images/logos/oneminute.png" alt="One Minute Print Logo"/>

                </Fade>
                </Link> 

            </div>


           

            <div className="child">
            <div className="line up"></div>
            <Link to="/Portofolio#prabadikaaset" className="">
            <Fade bottom>
                <img src="assets/images/logos/prabadikaset.png" alt="Prabadika Aset Logo"/>
                </Fade>
                </Link> 

            </div>

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#restoapung" className="">
             <Fade bottom>
                <img src="assets/images/logos/restoapung.png" alt="Resto Apung Logo"/>
                </Fade>   
                </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#sembada" className="">
             <Fade bottom>
                <img src="assets/images/logos/sembada2.png" alt="Sembada Logo"/>
                </Fade> 
                </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>   
            <Link to="/Portofolio#mataerparking" className="">
            <Fade bottom>
            <img src="assets/images/logos/mataerparking.png" alt="Mataer Parking Logo"/>
            </Fade> 
             </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>   
            <Link to="/Portofolio#dejia" className="">
            <Fade bottom>
            <img src="assets/images/logos/dejia-catering.png" alt="Dejia Catering Logo"/>
            </Fade>   
              </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>   
            <Link to="/Portofolio#spotless" className="">
            <Fade bottom>
            <img src="assets/images/logos/spotless.png" alt="Spotless Logo"/>
            </Fade> 
                </Link> 
            </div>

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#mataerdigital" className="">
             <Fade bottom>
            <img src="assets/images/logos/mataerdigital.png" alt="Mataer Digital Logo"/>
            </Fade>
            </Link> 
            </div>
           

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#profex" className="">
             <Fade bottom>
            <img src="assets/images/logos/profex.png" alt="Profex Logo"/>
            </Fade>
            </Link>
            </div>

            <div className="child">
            <div className="line up"></div>   
            <Link to="/Portofolio#chapterone" className="">
            <Fade bottom>
            <img src="assets/images/logos/chapterone.png" alt="Chapter One Logo"/>
            </Fade></Link>
            </div>

            <div className="child">
            <div className="line up"></div>  
            <Link to="/Portofolio#blambangan" className="">
             <Fade bottom>
            <img src="assets/images/logos/blambangan.png" alt="Blambangan Logo"/>
            </Fade></Link>
            </div>
            <div className="child">
            <div className="line up"></div>   

            <Fade bottom>
            <img src="assets/images/logos/aktif.png" alt="Aktif Mencari Tahu Media Logo"/>
            </Fade>
                      </div>

         

        </div>
    </div>

     </Fade>


</div>
</div>
</div>
</div>
</section>

<section className="bg-secondary bg-lm">
<div className="container-xxl">
<div className="row">
<div className="col-lg-10 mx-auto">
<p className="quote-text accelerate2">“Accelerate Your Future Safely With HOPE”</p>
<p className=" text-center startwithhope2">#StartWithHOPE</p>
<p className="text-center mb-0 hopeinvestment">Hope Investment</p>
</div>
</div>
</div>
</section>

</main>



<Footer/>
</Fragment>

    )
}

export default Index;